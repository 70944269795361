<template>
  <b-container
    class="container-2 container-collaborators-section center-vertical"
    fluid="flex"
    v-if="setContentReady()"
  >
    <div class="container-lg block-group-1">
      <div class="block-1 block" v-if="block1">
        <h2 v-html="block1.title"></h2>
        <span class="title-line"></span>
        <div class="block-content">
          <div
            class="block-item"
            v-for="(item, index) in block1.items"
            :class="'block-item-' + index"
            :key="index"
          >
            <div class="block-item-wrapper">
              <div class="icon-item icon-link" v-if="item.link">
                <a :href="item.link">
                  <b-img :src="item.image" />
                </a>
              </div>
              <div class="icon-item" v-else>
                <b-img :src="item.image" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "blockBoxContentCollaborators",
  props: {
    content: {
      required: true
    }
  },
  mounted() {},
  data() {
    return {
      helpers: this.$helperList,
      block1: null
    };
  },
  methods: {
    setContentReady() {
      if (this.block1 == null) {
        this.block1 = this.getBlock1();
      }

      return true;
    },
    getBlock1() {
      return {
        title: this.helpers.setTextAreaField(this.content.field_header_title),
        items: this.helpers.setIcons(this.content.field_icons)
      };
    }
  }
};
</script>

<style scoped></style>
