import Vue from "vue";
import VueRouter from "vue-router";
import VueBodyClass from "vue-body-class";

import routes from "./router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Go to top after going to other page
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      };
    } else {
      return { x: 0, y: 0 };
    }
    //document.getElementById('app').scrollIntoView();
  }
});
const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => {
  vueBodyClass.guard(to, next);
});

export default router;
