<template>
  <div class="container-wp">
    <div class="hero-wp">
      <block-hero :hero="getContent('header')" v-if="contentExist('header')"/>
    </div>
    <block-box-content
        :content="getContent('esfuerzo')"
        :mision="getContent('mision')"
        v-if="contentExist('mision') || contentExist('esfuerzo')"
    />

    <block-box-content-slider
        :content="getContentById(993)"
        v-if="contentExistByID(993)"
    />
    <block-box-content-block
        :block-data="getContentById(1010)"
        v-if="contentExistByID(993)"
    />
    <block-box-content-media
        :block-videos="getContentById(setContentByDate(995, 1006))"
        :block-data="getContentById(994)"
        :show-slider="isValidDate()"
        v-if="contentExistByID(994) || contentExistByID(995)"
    />
    <block-box-content-news
        :content="getContentById(996)"
        v-if="contentExistByID(996) && isValidDate()"
    />
    <block-box-content-collaborators
        :content="getContentById(997)"
        v-if="contentExistByID(997)"
    />
    <speed-bump/>
  </div>
</template>
<script>
import SpeedBump from "../components/sections/speedBump";
import BlockHero from "@/components/blocks/hero";
import BlockBoxContent from "@/components/blocks/boxContent.vue";
import BlockBoxContentSlider from "@/components/blocks/boxContentSlider.vue";
import BlockBoxContentMedia from "@/components/blocks/boxContentMedia.vue";
import BlockBoxContentNews from "@/components/blocks/boxContentNews.vue";
import BlockBoxContentCollaborators from "@/components/blocks/boxContentCollaborators.vue";
import BlockBoxContentBlock from "@/components/blocks/boxContentBlock.vue";

export default {
  name: "Home",
  data() {
    return {
      language: "es",
      helpers: this.$helperList,
      baseTemplate: "home",
      content: null
    };
  },
  components: {
    BlockBoxContentBlock,
    BlockBoxContentCollaborators,
    BlockBoxContentNews,
    BlockBoxContentMedia,
    BlockBoxContentSlider,
    BlockBoxContent,
    BlockHero,
    SpeedBump
  },
  async created() {
    await this.setContent();
  },
  computed: {},
  methods: {
    isValidDate() {
      const oct_1 = 1696219200;
      let timestamp = Math.round(+new Date() / 1000);
      return timestamp < oct_1;
    },
    setContentByDate(nid, newNid) {
      let timestamp = Math.round(+new Date() / 1000);

      if (timestamp > 1696219200) {
        return newNid;
      }

      return nid;
    },
    contentExistByID(nid) {
      const contentList = this.$store.getters["content/getContent"];
      if (contentList == null) {
        return false;
      }

      const currentContent = contentList.filter(item => {
        return item.drupal_internal__nid === nid;
      });
      if (currentContent) {
        const content = currentContent.length > 0 ? currentContent[0] : null;
        if (
            typeof content === "object" &&
            !Array.isArray(content) &&
            content !== null
        ) {
          return true;
        }
      }

      return false;
    },
    getContentById(nid) {
      const contentList = this.$store.getters["content/getContent"];
      if (contentList == null) {
        return false;
      }
      const currentContent = contentList.filter(item => {
        return item.drupal_internal__nid === nid;
      });

      return currentContent.length > 0 ? currentContent[0] : null;
    },
    contentExist(template) {
      const currentContent = this.$store.getters["content/getContentItem"](
          template
      );
      if (currentContent) {
        const content = currentContent.length > 0 ? currentContent[0] : null;
        if (
            typeof content === "object" &&
            !Array.isArray(content) &&
            content !== null
        ) {
          return true;
        }
      }

      return false;
    },
    getContent(template) {
      const currentContent = this.$store.getters["content/getContentItem"](
          template
      );

      return currentContent.length > 0 ? currentContent[0] : null;
    },
    //
    // getHeroData() {
    //   const localHelpers = this.helpers;
    //   return {
    //     title: this.content[0].field_fb_header_title,
    //     content: localHelpers.setTextAreaField(
    //       this.content[0].field_header_description
    //     ),
    //     image: localHelpers.setImageFiled(this.content[0].field_header_bg)
    //   };
    // },
    // getBottomContent() {
    //   const localHelpers = this.helpers;
    //   return [
    //     localHelpers.setButtonBlock(this.content, 0),
    //     localHelpers.setButtonBlock(this.content, 1)
    //   ];
    // },
    async setContent() {
      this.content = this.$store.getters["content/getContent"];
      if (this.content === null) {
        // if (this.content === null || this.content.length < 1) {
        await this.$store.dispatch("content/updateContent").finally(() => {
          this.content = this.$store.getters["content/getContent"];
        });
      }
    }
  },
  watch: {},
  mounted() {
  }
};
</script>
<style scoped lang="scss"></style>
