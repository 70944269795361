<template>
  <b-container
    class="container-2 container-slider-section center-vertical media-container-video"
    fluid="flex"
    v-if="setContentReady()"
  >
    <div class="container-lg slick-block-container">
      <h2 v-html="title"></h2>
      <span class="title-line"></span>
      <VueSlickCarousel v-bind="settings">
        <div
          class="gallery-item"
          v-for="(item, index) in slider"
          :class="'gallery-item-' + index"
          :key="index"
        >
          <div class="gallery-wrapper">
            <div class="gallery-image-container">
              <b-img :src="item.image" />
            </div>
            <div class="gallery-info-container">
              <h4 v-if="item.title" v-html="item.title" />
              <div
                class="description"
                v-if="item.description"
                v-html="item.description"
              />
            </div>
          </div>
        </div>
      </VueSlickCarousel>
      <div class="button-section hide-mobile" v-if="button">
        <a :href="button.link" class="btn btn-secondary slider">{{
          button.title
        }}</a>
      </div>
      <!--      <div class="button-section show-mobile">-->
      <!--        <button data-v-21137603="" data-v-3d1a4f76="" type="button" data-role="none" class="slick-arrow slick-prev slick-prev-mobile" style="display: block;bottom: 0;position: absolute;">Previous</button>-->
      <!--        <button data-v-21137603="" data-v-3d1a4f76="" type="button" data-role="none" class="slick-arrow slick-next slick-next-mobile" style="display: block;">Next</button>-->
      <!--      </div>-->
    </div>
  </b-container>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "blockBoxContentSlider",
  props: {
    content: {
      required: true
    }
  },
  components: { VueSlickCarousel },
  mounted() {},
  data() {
    return {
      helpers: this.$helperList,
      slider: null,
      title: null,
      button: null,
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    };
  },
  methods: {
    setContentReady() {
      if (this.button == null) {
        this.slider = this.getSlider();
        this.title = this.getTitle();
        this.button = this.getButton();
      }

      return true;
    },
    getTitle() {
      return this.helpers.setTextAreaField(this.content.field_header_title);
    },
    getButton() {
      return this.helpers.setButtonField(this.content.field_link);
    },
    getSlider() {
      return this.helpers.getGallery(this.content.field_gallery);
    }
  }
};
</script>

<style scoped></style>
