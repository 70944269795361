import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import AxiosAPI from "./services/axios";
import contentAPI from "./api/contentApi";
import interceptors from "@/utils/interceptors";
import store from "./store";
import helperList from "@/utils/helpers";
import VModal from "vue-js-modal";
import vue_cookies from "vue-cookies";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueMask from "v-mask";
Vue.use(VueMask);
Vue.use(vue_cookies);
import "./assets/scss/main.scss";
import VueGtag from "vue-gtag";

import LazyTube from "vue-lazytube";
Vue.use(LazyTube);

const canSetGA = Vue.$cookies.get("fb_uno_ambiente_status");

let ga = true;
const environment = process.env.VUE_APP_HDRUPAL_ENVIRONMENT;

if (canSetGA) {
  ga = canSetGA.analytics.value;
}

if(environment == 'dev'){
  ga = false;
}

if (ga) {
  Vue.use(
    VueGtag,
    {
      config: { id: `${process.env.VUE_APP_HDRUPAL_GTM}` },
      appName: "FirstBank Uno con el ambiente",
      pageTrackerScreenviewEnabled: true
    },
    router
  );
}

Vue.use(VModal, {
  dynamicDefaults: {
    adaptive: true,
    scrollable: true
  }
});

Vue.config.productionTip = false;
Vue.prototype.$contentApi = contentAPI;
Vue.prototype.$http = AxiosAPI;
Vue.prototype.$store = store;
Vue.prototype.$helperList = helperList;
interceptors();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

let app;
if (!app) {
  app = new Vue({
    el: "#app",
    store,
    router,
    contentAPI,
    methods: {
      initNormal() {
        store.dispatch("content/updateContent")
      }
    },
    created() {
      this.initNormal();
    },
    render: h => h(App)
  });
}
