<template>
  <b-container
    class="container-2 container-new-section center-vertical"
    fluid="flex"
    v-if="setContentReady()"
  >
    <div class="container-lg block-group-1">
      <div class="block-1 block" v-if="block1">
        <h2 v-html="block1.title"></h2>
        <span class="title-line"></span>
        <div class="block-content">
          <div
            class="block-item"
            v-for="(item, index) in block1.news"
            :class="'block-item-' + index"
            :key="index"
          >
            <div class="block-item-wrapper">
              <div class="news-date" v-html="item.date" v-if="item.date"></div>
              <div class="news-title" v-if="item.title">
                <a :href="item.link">{{ item.title }}</a>
              </div>
              <div class="news-link">
                <a :href="item.link" class="btn btn-secondary btn-news"
                  >Leer más</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "blockBoxContentNews",
  props: {
    content: {
      required: true
    }
  },
  mounted() {},
  data() {
    return {
      helpers: this.$helperList,
      block1: null
    };
  },
  methods: {
    setContentReady() {
      if (this.block1 == null) {
        this.block1 = this.getBlock1();
      }

      return true;
    },
    getBlock1() {
      return {
        title: this.helpers.setTextAreaField(this.content.field_header_title),
        news: this.helpers.setNews(this.content.field_list_of_links)
      };
    }
  }
};
</script>

<style scoped></style>
