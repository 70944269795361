import Vue from "vue";
import Vuex from "vuex";
import content from "./modules/content";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const dataState = createPersistedState({
  paths: ["menus.fl", "blocks.fl", "content.fl"]
});

const store = new Vuex.Store({
  modules: {
    content
  },
  plugins: [dataState]
});

export default store;
