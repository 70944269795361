<template>
  <div v-if="sectionIsReady()">
    <!-- Footer -->
    <b-container role="footer" class="footer-wrapper" fluid="flex">
      <div class="container-lg footer-container-wrapper">
        <div class="social-icons show-mobile" v-if="socialIcons">
          <div
            class="block-item"
            v-for="(item, index) in socialIcons"
            :class="'block-item-' + index"
            :key="index"
          >
            <a :href="item.link">
              <b-img :src="item.image" />
            </a>
          </div>
        </div>
        <div
          class="footer-copy"
          v-html="helpers.setTextAreaField(content.body)"
          v-if="content"
        />
        <div class="social-icons hide-mobile" v-if="socialIcons">
          <div
            class="block-item"
            v-for="(item, index) in socialIcons"
            :class="'block-item-' + index"
            :key="index"
          >
            <a :href="item.link">
              <b-img :src="item.image" />
            </a>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SiteFooter",
  data: function() {
    return {
      ready: false,
      language: "es",
      content: null,
      socialIcons: null,
      helpers: this.$helperList
    };
  },
  methods: {
    sectionIsReady() {
      if(this.ready) {
        return true;
      }
      const nid = 1008;
      const contentList = this.$store.getters["content/getContent"];
      if (contentList) {
        const currentContent = contentList.filter(item => {
          return item.drupal_internal__nid === nid;
        });
        if (currentContent.length > 0) {
          this.content = currentContent[0];
          this.socialIcons = this.getSocialIcons();
        }
        this.ready = true;
        return true;
      }

      return false;
    },
    getSocialIcons() {
      return this.helpers.setIcons(this.content.field_icons);
    }
  }
};
</script>
<style scoped lang="scss"></style>
