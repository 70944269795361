<template>
  <b-container
    class="container-2 container-media-section center-vertical media-container-video"
    v-if="setContentReady()"
    fluid="flex"
  >
    <div class="container-lg block-group-1">
      <div class="block-1 block" v-if="block1 && showSlider">
        <h2 v-html="block1.title"></h2>
        <span class="title-line"></span>
        <div
          class="block-1-description"
          v-html="block1.description"
          v-if="block1.description"
        />
        <div class="slick-block-container">
          <VueSlickCarousel v-bind="settings">
            <div
              class="gallery-item"
              v-for="(item, index) in block1.gallery"
              :class="'gallery-item-' + index"
              :key="index"
            >
              <div class="gallery-wrapper">
                <h3 v-if="item.title" v-html="item.title" />
                <div class="gallery-image-container">
                  <b-img :src="item.image" />
                </div>
                <div
                  class="description"
                  v-if="item.description"
                  v-html="item.description"
                />
              </div>
            </div>
          </VueSlickCarousel>
        </div>
        <div
          class="block-1-bottom-description"
          v-html="block1.bottom_description"
          v-if="block1.bottom_description"
        />
      </div>
      <div class="block-2 block" v-if="block2" :class="showSlider ? 'add-margin' : 'remove-margin center-video'">
        <h3 v-html="block2.title"></h3>
        <div class="block-2-content">
          <div
            class="block-2-item vide-item"
            v-for="(item, index) in block2.videoGallery"
            :class="'video-item-' + index"
            :key="index"
          >
            <div class="video-box">
              <LazyYoutube ref="youtubeLazyVideo" :src="item.video" />
            </div>
            <div
              class="video-description"
              v-html="item.description"
              v-if="item.description"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  name: "blockBoxContentMedia",
  props: {
    blockData: {
      required: true
    },
    blockVideos: {
      required: true
    },
    showSlider: {
      required: true,
    }
  },
  components: { VueSlickCarousel },
  mounted() {},
  data() {
    return {
      helpers: this.$helperList,
      block1: null,
      block2: null,
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchThreshold: 5
      }
    };
  },
  methods: {
    setContentReady() {
      if (this.block1 == null && this.block2 == null) {
        this.block1 = this.getBlock1();
        this.block2 = this.getBlock2();
      }

      return true;
    },
    getBlock1() {
      return {
        title: this.helpers.setTextAreaField(this.blockData.field_header_title),
        description: this.helpers.setTextAreaField(this.blockData.body),
        bottom_description: this.helpers.setTextAreaField(
          this.blockData.field_bottom_text
        ),
        gallery: this.helpers.getGallery(this.blockData.field_gallery)
      };
    },
    getBlock2() {
      return {
        title: this.helpers.setTextAreaField(
          this.blockVideos.field_header_title
        ),
        videoGallery: this.helpers.setVideoGallery(
          this.blockVideos.field_video_list
        )
      };
    }
  }
};
</script>

<style scoped></style>
