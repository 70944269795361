import store from "@/store";

export default {
  isNormalUrl(uri){
    if (this.isUrlExternal(uri)) {
      if (this.isNotBankUrl(uri)) {
            return false;
      }
    }

    return true;
  },
  isNotBankUrl(str){
    let bankStr = "1firstbank.com";
    let bankStrIo = "1firstbank.io";
    let smartCash = "https://firstbankpr.ebanking-services.com/";
    let bankPR = "firstbankpr.";
    let isBankStr = parseInt(str.search(bankStr)) >= 0;
    let isBankStrIo = parseInt(str.search(bankStrIo)) >= 0;
    let isSmartCash = parseInt(str.search(smartCash)) >= 0;
    let isBankPR = parseInt(str.search(bankPR)) >= 0

    if(isBankStr || isSmartCash || isBankPR || isBankStrIo){
      return false;
    }

    return true;
  },
  isUrlExternal(str){
    let tel = "tel:";
    let isTel = str.indexOf(tel) !== -1;
    if (isTel) {
      return false;
    }
    let regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
      return true;
    } else {
      return false;
    }
  },
  getRouteParameters() {
    return {
      lang: "en",
      region: "FL"
    };
  },
  getToken() {
    let tokenData = store.getters["getToken"];

    if (!tokenData) {
      return false;
    }

    return tokenData;
  },
  validToken(tokenData) {
    const time = tokenData.created;
    const currentTime = Math.round(+new Date() / 1000);
    const timeDistance = currentTime - time;
    if (timeDistance > 18000) {
      return false;
    }

    return true;
  },
  setButtonField(field) {
    if (!field) {
      return false;
    }

    return {
      url: this.getMenuUrl(field.uri),
      title: field.title
    };
  },
  setListField(field) {
    if (!field) {
      return null;
    }
    const localThis = this;
    return field.map(item => {
      return {
        image: localThis.setImageFiled(item.field_pg_icon),
        title: item.field_pg_title,
        link: {
          url: localThis.getMenuUrl(item.field_pg_link.uri),
          title: localThis.setTextAreaField(item.field_pg_content)
        }
      };
    });
  },
  setButtonBlock(content, id) {
    if (!content[0].field_basic_blocks[id]) {
      return false;
    }

    let contentList = false;
    if (id === 1) {
      contentList = this.setListField(content[0].field_content_list);
    }

    const data = content[0].field_basic_blocks[id];

    return {
      id: 1,
      image: this.setImageFiled(data.field_pg_icon),
      title: data.field_pg_title,
      description: this.setTextAreaField(data.field_pg_content),
      button: this.setButtonField(data.field_pg_link),
      list: contentList
    };
  },
  setListContentData(field) {
    if (!field) {
      return null;
    }
    const localThis = this;
    if(field.data.length === 0) {
      return null;
    }
    console.log(field);
    return field.map(item => {
      return {
        title: item.field_pg_title,
        description: localThis.setTextAreaField(item.field_pg_description)
      }
    });
  },
  setTextAreaField(field) {
    if (!field) {
      return null;
    }

    return field.value;
  },
  haveSubMenu(html, items) {
    if (html) {
      return true;
    }
    if (items.length > 1) {
      return true;
    }

    return false;
  },
  setImageFiled(field) {
    if (!field) {
      return null;
    }
    if (!field.uri) {
      return null;
    }
    return this.getFullImagePath(field.uri.url);
  },
  getFullImagePath(uri) {
    if (uri) {
      return `${process.env.VUE_APP_HDRUPAL_BASE_URL}${uri}`;
    }

    return null;
  },
  getMenuUrl(uri) {
    if (uri === "route:<nolink>") {
      return false;
    }
    let cleanURI = uri.replace("internal:/es/", "/es/");
    if (uri.includes("internal:/es/")) {
      cleanURI = uri.replace("internal:/es/", "/es/");
      return cleanURI;
    }
    if (uri.includes("internal:/en/")) {
      cleanURI = uri.replace("internal:/en/", "/en/");
      return cleanURI;
    }
    if (uri.includes("internal:#")) {
      cleanURI = uri.replace("internal:#", `#`);
      return cleanURI;
    }
    if (uri.includes("internal:/")) {
      cleanURI = uri.replace("internal:/", `/`);
      return cleanURI;
    }

    return uri;
  },
  renderSpanString(name) {
    const data = name.split("--");
    if (data.length > 1) {
      return data[0] + "<strong>" + data[1] + "</strong>";
    }

    return name;
  },
  setVideoGallery(field) {
    if (!field) {
      return null;
    }
    const localThis = this;
    return field.map(item => {
      return {
        description: localThis.setTextAreaField(item.field_pg_content),
        video: localThis.setVideoField(item.field_pg_menu_link),
      };
    });
  },
  setVideoField(field) {
    if (!field) {
      return false;
    }
    if (!field.uri) {
      return false;
    }
    return this.getMenuUrl(field.uri);
  },
  setNews(field) {
    if (!field) {
      return null;
    }
    const localThis = this;
    return field.map(item => {
      return {
        date: localThis.setTextAreaField(item.field_text_date),
        title: localThis.setTextAreaField(item.field_pg_title_format),
        link: localThis.setVideoField(item.field_pg_link),
      }
    });
  },
  setIcons(field) {
    if (!field) {
      return null;
    }

    const localThis = this;
    return field.map(item => {
      return {
        image: localThis.setImageFiled(item.field_pg_icon),
        title: item.field_pg_title,
        link: localThis.setVideoField(item.field_pg_link),
      };
    });
  },
  getGallery(field) {
    if (!field) {
      return null;
    }

    const localThis = this;
    return field.map(item => {
      return {
        image: localThis.setImageFiled(item.field_pg_image),
        title: item.field_pg_title,
        description: localThis.setTextAreaField(item.field_pg_content),
      };
    });
  },
};
