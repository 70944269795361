<template>
  <div id="app">
    <SiteHeader />
    <router-view />
    <CookieModal />
    <SiteFooter />
  </div>
</template>
<script>
import SiteHeader from "@/components/Header";
import SiteFooter from "./components/Footer";
import CookieModal from "./components/cookieModal";
export default {
  components: {
    CookieModal,
    SiteFooter,
    SiteHeader
  }
};
</script>
<style scoped lang="scss"></style>
