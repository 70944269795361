<template>
  <b-container
    class="container-2 container-info-section center-vertical"
    fluid="flex"
    v-if="setContentReady()"
  >
    <div class="container-lg block-group-1">
      <div class="block-1 block" v-if="block1">
        <h2 v-html="block1.title"></h2>
        <span class="title-line"></span>
        <div class="block-description">
          <div
            class="block-description-container"
            v-html="block1.description"
          ></div>
        </div>
      </div>
      <div class="block-2 block">
        <block-box-event />
      </div>
    </div>
    <div class="container-lg block-group-2">
      <div class="block-3 block" v-if="block2">
        <b-img :src="block2.image" />
        <div class="list-events" v-if="block2.list_content">
          <h2 v-html="block2.list_title"></h2>
          <div class="list-events-container" v-for="(item, index) in block2.list_content" :key="index">
            <div class="event-item">
              <div class="title" v-html="item.title"></div>
              <div class="description" v-html="item.description"></div>
            </div>
          </div>
        </div>
        <div class="list-events" v-else>
<!--          <h2 class="center">Pronto anunciaremos la ruta de los próximos eventos.</h2>-->
        </div>
      </div>
      <div class="block-4 block" v-if="block2">
        <h2 v-html="block2.title"></h2>
        <span class="title-line"></span>
        <div class="block-description">
          <div
            class="block-description-container"
            v-html="block2.description"
          ></div>
          <div class="video-wrapper" v-if="block2.button">
            <b-button class="video" v-b-modal.block-4-modal>{{
              block2.button.title
            }}</b-button>
            <b-modal id="block-4-modal" hide-footer centered>
              <iframe
                class="embed-responsive-item"
                :src="block2.button.url"
                id="video"
                allowscriptaccess="always"
                allow="autoplay"
              ></iframe>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import BlockBoxEvent from "@/components/blocks/boxEvent.vue";

export default {
  name: "blockBoxContent",
  components: { BlockBoxEvent },
  props: {
    content: {
      required: true
    },
    mision: {
      required: true
    }
  },
  mounted() {},
  data() {
    return {
      helpers: this.$helperList,
      block1: null,
      block2: null
    };
  },
  methods: {
    setContentReady() {
      if (this.block1 == null && this.block2 == null) {
        this.block1 = this.getBlock1();
        this.block2 = this.getBlock2();
        return true;
      }

      return true;
    },
    getBlock1() {
      return {
        title: this.helpers.setTextAreaField(this.content.field_header_title),
        description: this.helpers.setTextAreaField(this.content.body)
      };
    },
    getBlock2() {
      return {
        title: this.helpers.setTextAreaField(this.mision.field_header_title),
        description: this.helpers.setTextAreaField(this.mision.body),
        button: this.helpers.setButtonField(this.mision.field_link),
        image: this.helpers.setImageFiled(this.mision.field_image),
        list_title: this.mision.field_list_title,
        list_content: this.helpers.setListContentData(this.mision.field_items_1),
      };
    }
  }
};
</script>

<style scoped></style>
