import Home from "@/views/Home";
import Reset from "@/views/Reset";
import cookiesPage from "../views/cookies.vue";

export default [
  {
    path: "/cookies",
    name: "cookiesEn",
    component: cookiesPage
  },
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/reset",
    name: "RESET",
    component: Reset
  },
  {
    path: "*",
    name: "Home",
    component: Home
  }
];
