<template>
  <b-container
    class="container-2-1 container-media-section center-vertical media-container-video"
    v-if="setContentReady()"
    fluid="flex"
  >
    <div class="container-lg block-group-1">
      <div class="block-1 block" v-if="block1">
        <h2 v-html="block1.title"></h2>
        <span class="title-line"></span>
        <div
          class="block-1-description"
          v-html="block1.description"
          v-if="block1.description"
        />
        <div class="slick-block-container" v-if="block1.image">
          <b-img :src="block1.image" />
        </div>
        <div
          class="block-1-bottom-description"
          v-html="block1.bottom_description"
          v-if="block1.bottom_description"
        />
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "blockBoxContentBlock",
  props: {
    blockData: {
      required: true
    }
  },
  mounted() {},
  data() {
    return {
      helpers: this.$helperList,
      block1: null,
    };
  },
  methods: {
    setContentReady() {
      if (this.block1 == null) {
        this.block1 = this.getBlock1();
      }

      return true;
    },
    getBlock1() {
      return {
        title: this.helpers.setTextAreaField(this.blockData.field_header_title),
        description: this.helpers.setTextAreaField(this.blockData.body),
        image: this.helpers.setImageFiled(this.blockData.field_image)
      };
    },
  }
};
</script>

<style scoped></style>
