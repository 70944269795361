import { API_ENDPOINTS } from "../utils/apiCollection";
import $http from "@/services/axios";

async function getContent(lang) {
  const contentURI = `/${lang}${API_ENDPOINTS.GET_CONTENT}`;
  let params = {
    jsonapi_include: "1",
    "filter[field_gid]": API_ENDPOINTS.GID
  };

  return await $http.get(`${contentURI}`, {
    params
  });
}

export { getContent };
