import * as contentApi from "@/api/contentApi";

const content = {
  namespaced: true,
  state: {
    content: null
  },
  mutations: {
    resetAllContent(state, payload) {
      state.content = payload;
    },
    setContent(state, payload) {
      state.content = payload;
    }
  },
  actions: {
    resetContentList(context) {
      context.commit("resetAllContent", null);
    },
    async updateContent(context) {
      const lang = 'es';
      await contentApi
        .getContent(lang)
        .then(({ data }) => {
          context.commit("setContent", data.data);
        })
        .catch(error => {
          console.log("ERROR ON CALL", error);
        });
    }
  },
  getters: {
    getContent: state => {
      return state.content;
    },
    getContentItem: state => template => {
      let data = null;
      data = state.content;
      if (data) {
        if (template) {
          return data.filter(dataItem => {
            return dataItem.field_template == template;
          });
        }
      }

      return null;
    }
  }
};

export default content;
