<template>
  <header class="site-header" role="banner">
    <b-container fluid="lg" class="">
      <b-row>
        <div
          id="block-linkatras"
          class="header-btns"
          role="navigation"
          aria-label="Navigation"
        >
          <a href="https://1firstbank.com" class="back-to">1firstbank.com</a>
        </div>
        <div class="site-logo">
          <router-link :to="headerLink">
            <div class="site-branding">
              <span class="screen-reader-text" aria-label="FirstBank"
                >FirstBank</span
              >
            </div>
          </router-link>
        </div>
      </b-row>
    </b-container>
  </header>
</template>

<script>
export default {
  name: "SiteHeader",
  data: function() {
    return {
      language: "es",
      headerLink: "/",
      helpers: this.$helperList
    };
  },
  methods: {},
  watch: {},
  mounted() {}
};
</script>
<style scoped lang="scss">
.site-logo {
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-brand-box {
  @media (max-width: 1024px) {
    width: 100%;
    .navbar-brand {
      float: left;
      padding-right: 15px;
      border-right: 1px solid #f0f0f0;
      margin-right: 15px;
    }
    .aside__selectors {
      margin-top: 9px;
      float: left;
      width: auto;
    }
  }
}
.desktop-logo {
  display: none !important;
  @media (min-width: 1024px) {
    display: block !important;
  }
}
.mobile-menu-data {
  @media (min-width: 1025px) {
    display: none;
  }
}
.mobile-logo {
  display: none !important;
  @media (max-width: 1024px) {
    display: block !important;
  }
}
.fbfBanner h1 {
  text-align: center;
  font-size: 45px;
  line-height: 1.13;
  letter-spacing: -0.84px;
  font-weight: bold;
  width: 100%;
  @media (max-width: 800px) {
    font-size: 30px;
  }
  @media (max-width: 600px) {
    font-size: 22px;
    text-align: left;
  }
}
.nav-link {
  border-right: 1px solid #fff;
  padding-right: 8px;
  font-size: 12px;
  font-family: "Helvetica", Arial, sans-serif !important;
}
</style>
