<template>
  <b-container
    v-if="isReady"
    class="container-1 c-hero center-vertical"
    fluid="flex"
    :style="`background-image: url(${getBackground()});`"
  >
    <div class="img-container hide-desktop">
      <b-img :src="getBackground()" />
    </div>
    <div class="container-lg">
      <div class="hero-box">
        <div class="hero-box-content" v-html="getContent()"></div>
        <div class="hero-btn-section" v-if="buttonLink">
          <a
            :href="buttonLink.url"
            v-html="buttonLink.title"
            class="btn btn-general"
          ></a>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "blockHero",
  props: {
    hero: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      ready: false,
      helpers: this.$helperList,
      buttonLink: null
    };
  },
  methods: {
    isReady() {
      return true;
    },
    getBackground() {
      return this.helpers.setImageFiled(this.hero.field_image);
    },
    getContent() {
      if (!this.ready) {
        this.setButton();
      }
      return this.helpers.setTextAreaField(this.hero.body);
    },
    setButton() {
      this.ready = true;
      this.buttonLink = this.helpers.setButtonField(this.hero.field_link);
    }
  }
};
</script>

<style scoped></style>
